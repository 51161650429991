import { defineStyleConfig } from '@chakra-ui/react'
import { TOAST_Z_INDEX } from '~/constants/zIndex'

export const ImageView = defineStyleConfig({
  baseStyle: {
    root: {
      flexDir: 'column',
      bg: 'standard.black',
      position: 'absolute',
      top: 0,
      left: 0,
      h: '100dvh',
      w: '100dvw',
      zIndex: TOAST_Z_INDEX,
    },
    arrowIcon: {
      position: 'absolute',
      top: '50%',
      bg: 'black',
      opacity: '0.5',
      border: 'none',
      borderRadius: '50%',
      maxW: '2.5rem',
      maxH: '2.5rem',
      minW: '2.5rem',
      minH: '2.5rem',
      WebkitTapHighlightColor: 'transparent',
      _focus: {
        bg: 'black',
      },
      _active: {
        opacity: '0.8',
        bg: 'black',
      },
    },
  },
})
