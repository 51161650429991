import {
  CheckboxQuestion,
  DateTimeFields,
  DateTimeQuestion,
  Item,
  PhotoQuestion,
  QuantityQuestion,
  QuestionType,
  Record,
  SingleOptionSelectQuestion,
  TextboxQuestion,
} from '@prisma/client'
import _ from 'lodash'
import moment from 'moment'
import { z } from 'zod'
import {
  CheckboxRecordType,
  DateTimeRecordType,
  PhotoRecordType,
  QuantityRecordType,
  SingleOptionSelectRecordType,
  TextboxRecordType,
} from '~/metadata'
import { evaluateCondition } from '~/state/conditions/evaluate-condition'
import { datetimeFieldSchema } from './client'

// the item augmented with the appropriate question type
export type AugmentedItem<T extends { id: string; itemType: QuestionType }> =
  T & {
    question:
      | QuantityQuestion
      | PhotoQuestion
      | SingleOptionSelectQuestion
      | CheckboxQuestion
      | TextboxQuestion
      | DateTimeQuestion
    // this is a bit of a workaround since I don't quite know how to make the below work
    // THIS IS LEADING TO A NEVER BEING INFERRED - SOMETHING WRONG WITH THE TYPE NARROWING WHEN INFERRNG AN ITEM
    // TODO: FIX THIS
    // question: T['itemType'] extends 'QUANTITY' | 'PHOTO'
    //   ? QuantityQuestion
    //   : T['itemType'] extends 'PHOTO'
    //     ? PhotoQuestion
    //     : never
  }

// a primitive type which we should avoid recursing futher into
type Primitive = string | number | boolean | symbol | bigint | Date

// checks if T is a primitive - if it is, stop processing further
// else if it is an object, recurse through the object and perform the augmentation

export type AugmentedObject<T> = T extends Primitive
  ? T
  : T extends { id: string; itemType: QuestionType }
    ? AugmentedItem<T>
    : {
        // checks whether U has the correct type (ie the itemType attached)
        [K in keyof T]: T[K] extends Array<infer U>
          ? U extends { id: string; itemType: QuestionType }
            ? // if so, augments it with the question type deduced
              AugmentedItem<U>[]
            : // else, contrinue traversing the nested array
              AugmentedObject<U>[]
          : // recursively applies the transformation if T[K] is not an array
            T[K] extends { id: string; itemType: QuestionType }
            ? AugmentedItem<T[K]>
            : AugmentedObject<T[K]>
      }

// types and utils for photo itesm
export type AugmentedPhotoItem = AugmentedItem<
  Omit<Item, 'itemType' | 'question'> & {
    itemType: (typeof QuestionType)['PHOTO']
    question: PhotoQuestion
  }
>

export type PhotoRecord = Omit<Record, 'metadata'> & {
  metadata: Omit<PrismaJson.RecordMetadata, 'question'> & {
    question: PhotoRecordType
  }
}

// typeguard to check if an AugmentedItem is a PhotoItem or not, to make generic
export const isPhotoItem = (
  item: AugmentedItem<Item>,
): item is AugmentedPhotoItem => {
  return item.itemType === 'PHOTO'
}

export const isPhotoRecord = (record: Record): record is PhotoRecord => {
  return record.metadata.question.type === 'PHOTO'
}

// types and utils for quantity items
export type AugmentedQuantityItem = AugmentedItem<
  Omit<Item, 'itemType' | 'question'> & {
    itemType: (typeof QuestionType)['QUANTITY']
    question: QuantityQuestion
  }
>

type QuantityRecord = Omit<Record, 'metadata'> & {
  metadata: Omit<PrismaJson.RecordMetadata, 'question'> & {
    question: QuantityRecordType
  }
}

export type AugmentedSingleOptionSelectItem = AugmentedItem<
  Omit<Item, 'itemType' | 'question'> & {
    itemType: (typeof QuestionType)['SINGLE_OPTION_SELECT']
    question: SingleOptionSelectQuestion
  }
>

type SingleOptionSelectRecord = Omit<Record, 'metadata'> & {
  metadata: Omit<PrismaJson.RecordMetadata, 'question'> & {
    question: SingleOptionSelectRecordType
  }
}

export type AugmentedCheckboxItem = AugmentedItem<
  Omit<Item, 'itemType' | 'question'> & {
    itemType: (typeof QuestionType)['CHECKBOX']
    question: CheckboxQuestion
  }
>

type CheckboxRecord = Omit<Record, 'metadata'> & {
  metadata: Omit<PrismaJson.RecordMetadata, 'question'> & {
    question: CheckboxRecordType
  }
}

// typeguards for quantity qs

export const isQuantityItem = (
  item: AugmentedItem<Item>,
): item is AugmentedQuantityItem => {
  return item.itemType === 'QUANTITY'
}

export const isQuantityRecord = (record: Record): record is QuantityRecord => {
  return record.metadata.question.type === 'QUANTITY'
}

// typeguards for single option select

export const isSingleOptionSelectItem = (
  item: AugmentedItem<Item>,
): item is AugmentedSingleOptionSelectItem => {
  return item.itemType === 'SINGLE_OPTION_SELECT'
}

export const isSingleOptionSelectRecord = (
  record: Record,
): record is SingleOptionSelectRecord => {
  return record.metadata.question.type === 'SINGLE_OPTION_SELECT'
}

// typeguards for checkbox

export const isCheckboxItem = (
  item: AugmentedItem<Item>,
): item is AugmentedCheckboxItem => {
  return item.itemType === 'CHECKBOX'
}

export const isCheckboxRecord = (record: Record): record is CheckboxRecord => {
  return record.metadata.question.type === 'CHECKBOX'
}

// typeguards for textbox

export type AugmentedTextboxItem = AugmentedItem<
  Omit<Item, 'itemType' | 'question'> & {
    itemType: (typeof QuestionType)['TEXTBOX']
    question: TextboxQuestion
  }
>

type TextboxRecord = Omit<Record, 'metadata'> & {
  metadata: Omit<PrismaJson.RecordMetadata, 'question'> & {
    question: TextboxRecordType
  }
}

export const isTextboxItem = (
  item: AugmentedItem<Item>,
): item is AugmentedTextboxItem => {
  return item.itemType === 'TEXTBOX'
}

export const isTextboxRecord = (record: Record): record is TextboxRecord => {
  return record.metadata.question.type === 'TEXTBOX'
}

// typeguards for datetime

export type AugmentedDateTimeItem = AugmentedItem<
  Omit<Item, 'itemType' | 'question'> & {
    itemType: (typeof QuestionType)['DATETIME']
    question: DateTimeQuestion
  }
>

// is this record a generic date time record?
type DateTimeRecord = Omit<Record, 'metadata'> & {
  metadata: Omit<PrismaJson.RecordMetadata, 'question'> & {
    question: DateTimeRecordType
  }
}

export const isDatetimeItem = (
  item: AugmentedItem<Item>,
): item is AugmentedDateTimeItem => {
  return item.itemType === 'DATETIME'
}

// general type guard for checking it's a datetime record
export const isDatetimeRecord = (record: Record): record is DateTimeRecord => {
  return record.metadata.question.type === 'DATETIME'
}

export const getDatetimeDisplayFormat = (
  actual: NonNullable<DateTimeRecord['metadata']['question']['actual']>,
) => {
  switch (actual.datetimeType) {
    case 'DATE':
      return moment(actual.date).format('DD//MM/YYYY')
    case 'DATE_AND_TIME':
      return moment(actual.date)
        .add({
          hours: moment(actual.time, 'HH:mm', true).hours(),
          minutes: moment(actual.time, 'HH:mm', true).minutes(),
        })
        .format('DD/MM/YYYY, hh:mm a')
    case 'TIME':
      return moment(actual.time, 'HH:mm', true).format('hh:mm a')
  }
}

/**
 * An object containing the inputs for each question type which are INVALID, and should cause the submission to fail
 * The type utility operates on all question types and extracts the possible inputs to the question. The reason we don't do this
 * in the zod schema directly is because we need the verification counts on the FE to determine if the user has filled up everything correctly
 */
type InputForQuestion<
  T extends QuestionType,
  K extends 'actual' | 'expected',
> = T extends typeof QuestionType.QUANTITY
  ? QuantityRecordType[K]
  : T extends typeof QuestionType.PHOTO
    ? PhotoRecordType[K]
    : T extends typeof QuestionType.SINGLE_OPTION_SELECT
      ? SingleOptionSelectRecordType[K]
      : T extends typeof QuestionType.CHECKBOX
        ? CheckboxRecordType[K]
        : T extends typeof QuestionType.TEXTBOX
          ? TextboxRecordType[K]
          : T extends typeof QuestionType.DATETIME
            ? DateTimeRecordType[K]
            : never

export const UnacceptableInputsForQuestion: {
  [key in QuestionType]: (
    actual: InputForQuestion<key, 'actual'>,
    expected: InputForQuestion<key, 'expected'>,
  ) => boolean
} = {
  [QuestionType.DATETIME]: (
    actual: z.infer<typeof datetimeFieldSchema> | null,
  ) => {
    if (actual === null) return true
    switch (actual.datetimeType) {
      case DateTimeFields.DATE:
        return !moment(actual.date).isValid()
      case DateTimeFields.TIME:
        return !moment(actual.time, 'HH:mm', true).isValid()
      case DateTimeFields.DATE_AND_TIME:
        return (
          !moment(actual.date).isValid() ||
          !moment(actual.time, 'HH:mm', true).isValid()
        )
    }
  },
  [QuestionType.TEXTBOX]: (input): input is string => input === '',
  [QuestionType.QUANTITY]: (quantity, _expected): quantity is number =>
    quantity === null || !Number.isInteger(quantity) || Number(quantity) < 0,
  [QuestionType.PHOTO]: (images, expected) => images.length !== expected,
  [QuestionType.SINGLE_OPTION_SELECT]: (
    selected,
    expected,
  ): selected is string =>
    selected === null || !expected.map((e) => e.value).includes(selected),
  [QuestionType.CHECKBOX]: (selected): selected is true => selected === false, // the checkbox MUST be checked for the check to be submitted
} as const

/**
 * An object containing the default values for each question type which should be adopted by the record during its creation
 * Some of these inputs may overlap with the UnacceptableInputs, that's because we want to provide a 'blank' canvas for the user to fill in the records
 * If they attempt to submit with some of the records still equal to UnacceptableInputs, it will lead to an error being thrown
 */

export const DefaultInputsForQuestion = {
  [QuestionType.DATETIME]: null,
  [QuestionType.QUANTITY]: null,
  [QuestionType.PHOTO]: [] as PrismaJson.RecordImages,
  [QuestionType.SINGLE_OPTION_SELECT]: null, // in the beginning, no options are selected
  [QuestionType.CHECKBOX]: false, // in the beginning the checkbox is not checked
  [QuestionType.TEXTBOX]: '',
} as const

/**
 * @param record The record to check for validity
 * @returns a boolean indicating whether the record provided is valid; this ASSUMES that the record should be displayed
 * (ie the condition to display the record has been fulfilled)
 */
export const isRecordInvalid = (record: Record & { item: Item }): boolean => {
  switch (record.metadata.question.type) {
    case 'PHOTO':
      if (!isPhotoRecord(record))
        throw new Error(
          `The record and item types for ${record.item.name} are inconsistent`,
        )
      return UnacceptableInputsForQuestion[record.metadata.question.type](
        record.metadata.question.actual,
        record.metadata.question.expected,
      )
    case 'QUANTITY':
      if (!isQuantityRecord(record))
        throw new Error(
          `The record and item types for ${record.item.name} are inconsistent`,
        )
      return UnacceptableInputsForQuestion[record.metadata.question.type](
        record.metadata.question.actual,
        record.metadata.question.expected,
      )
    case 'SINGLE_OPTION_SELECT':
      if (!isSingleOptionSelectRecord(record))
        throw new Error(
          `The record and item types for ${record.item.name} are inconsistent`,
        )

      return UnacceptableInputsForQuestion[record.metadata.question.type](
        record.metadata.question.actual,
        record.metadata.question.expected,
      )
    case 'CHECKBOX':
      if (!isCheckboxRecord(record))
        throw new Error(
          `The record and item types for ${record.item.name} are inconsistent`,
        )

      return UnacceptableInputsForQuestion[record.metadata.question.type](
        record.metadata.question.actual,
        record.metadata.question.expected,
      )
    case 'TEXTBOX':
      if (!isTextboxRecord(record))
        throw new Error(
          `The record and item types for ${record.item.name} are inconsistent`,
        )

      return UnacceptableInputsForQuestion[record.metadata.question.type](
        record.metadata.question.actual,
        record.metadata.question.expected,
      )
    case 'DATETIME':
      if (!isDatetimeRecord(record))
        throw new Error(
          `The record and item types for ${record.item.name} are inconsistent`,
        )

      return UnacceptableInputsForQuestion[record.metadata.question.type](
        record.metadata.question.actual,
        record.metadata.question.expected,
      )
    default:
      const exhaustiveCheck: never = record.metadata.question
      throw new Error(`Unhandled case: ${exhaustiveCheck}`)
  }
}

/**
 *
 * @param record The record to evaluate the condition for, to determine whether it is active
 */
export const isRecordActive = (
  record: Record & { item: Item },
  recordsInSubmissionByItemId: {
    [itemId: string]: Record & { item: Item }
  },
): boolean => {
  let recordToVerify = record
  let dependentItemId = record.item.dependsOnItemId
  let isActive = true
  // this item does not depend on another item if dependentItemId is null, and hence should always be active
  while (dependentItemId) {
    const depItemRecord = recordsInSubmissionByItemId[dependentItemId]
    if (!depItemRecord)
      throw new Error(
        `Could not find record for the dependent item with id ${recordToVerify.item.dependsOnItemId}`,
      )
    // if the condition is false, the record is not active, and hence should not be displayed
    isActive =
      isActive &&
      !!evaluateCondition(recordToVerify.item.condition, depItemRecord)
    if (!isActive) return false
    dependentItemId = depItemRecord.item.dependsOnItemId
    recordToVerify = depItemRecord
  }
  return isActive
  // evaluates the condition to determine whether the record is in an active state
}

/**
 *
 * @param recordsInSubmissionByItemId A map of all the records inside the submission, keyed by the itemId of the record
 * @returns a boolean indicating whether the submission provided is valid
 */
export const checkAllActiveRecordsAreValid = (
  records: (Record & { item: AugmentedItem<Item> })[],
): {
  valid: boolean
  inactiveRecords: (Record & { item: AugmentedItem<Item> })[]
} => {
  const recordsInSubmissionByItemId = _.chain(records)
    .keyBy((record) => record.itemId)
    .value()
  const [activeRecords, inactiveRecords] = _.partition(
    Object.values(recordsInSubmissionByItemId),
    (record) => isRecordActive(record, recordsInSubmissionByItemId),
  )
  return {
    valid: activeRecords.every(
      (activeRecord) => !isRecordInvalid(activeRecord),
    ),
    inactiveRecords,
  }
}
