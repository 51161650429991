/* eslint @typescript-eslint/no-namespace: 0 */ // --> OFF

import { DateTimeFields, QuestionType } from '@prisma/client'
import { z } from 'zod'
import { cutoffSchema } from './features/admin/listTemplate/create/CreateRowDrawer'
import { DefaultInputsForQuestion } from './state/schemas/questions/utils'
import {
  dateField,
  datetimeField,
  timeField,
} from './state/schemas/questions/client'

// THIS FILE DEFINES TYPES FOR METADATA FIELDS, SUBSEQUENTLY USED BY prisma-json-types-generator TO TYPE METADATA FIELDS

declare global {
  namespace PrismaJson {
    type ItemMetadata = {
      // indicates the position inside the parent section
      positionInsideParent: number
      // indicates the position of the itemset inside the parent
      // only applicable if the item is inside an itemset
      itemsetPosition?: number
    }
    type RecordMetadata = {
      question:
        | QuantityRecordType
        | PhotoRecordType
        | SingleOptionSelectRecordType
        | CheckboxRecordType
        | TextboxRecordType
        | DateTimeRecordType
    }
    type TeamMetadata = {
      inviteCode?: string
      contactEmail?: Array<string>
    }
    type RecordImages = Array<{
      name: string
      key: string
      aspectRatio?: number // the aspect ratio which should be used to display the image
      submittedAt: string
    }>
    type SingleOptionSelectQuestionMetadata = {
      value: string
      label: string
      color: string
      bg: string
      score?: number
    }[]
    type IssueImages = Array<{
      name: string
      key: string
      submittedAt: string
      size?: number
    }>
    type ConditionMetadata = {
      cutoff?: z.infer<typeof cutoffSchema>
    }
  }
}

export enum Operator {
  EQUAL = 'EQUAL',
}

export type QuantityRecordType = {
  type: (typeof QuestionType)['QUANTITY']
  expected: number // refers to the expectedQuantity which the item is supposed to have
  actual: number | (typeof DefaultInputsForQuestion)['QUANTITY']
}

export type PhotoRecordType = {
  type: (typeof QuestionType)['PHOTO']
  expected: number // refers to the expected number of photos which the user is supposed to provide
  actual: PrismaJson.RecordImages | (typeof DefaultInputsForQuestion)['PHOTO']
}

export type SingleOptionSelectRecordType = {
  type: (typeof QuestionType)['SINGLE_OPTION_SELECT']
  actual: string | (typeof DefaultInputsForQuestion)['SINGLE_OPTION_SELECT']
  expected: PrismaJson.SingleOptionSelectQuestionMetadata
  score?: number
}

export type CheckboxRecordType = {
  type: (typeof QuestionType)['CHECKBOX']
  actual: boolean | (typeof DefaultInputsForQuestion)['CHECKBOX']
  expected: undefined // TODO: deprecate this field throughout
  score?: number
}

export type TextboxRecordType = {
  type: (typeof QuestionType)['TEXTBOX']
  actual: string | (typeof DefaultInputsForQuestion)['TEXTBOX']
  expected: undefined // TODO: deprecate this field throughout
}

export type DateTimeRecordType_DATE_ONLY = {
  type: (typeof QuestionType)['DATETIME']
  actual:
    | z.infer<typeof dateField>
    | (typeof DefaultInputsForQuestion)['DATETIME']
  expected: (typeof DateTimeFields)['DATE']
}

export type DateTimeRecordType_TIME_ONLY = {
  type: (typeof QuestionType)['DATETIME']
  actual:
    | z.infer<typeof timeField>
    | (typeof DefaultInputsForQuestion)['DATETIME']
  expected: (typeof DateTimeFields)['TIME']
}

export type DateTimeRecordType_DATE_AND_TIME = {
  type: (typeof QuestionType)['DATETIME']
  actual:
    | z.infer<typeof datetimeField>
    | (typeof DefaultInputsForQuestion)['DATETIME']
  expected: (typeof DateTimeFields)['DATE_AND_TIME']
}

export type DateTimeRecordType =
  | DateTimeRecordType_DATE_AND_TIME
  | DateTimeRecordType_DATE_ONLY
  | DateTimeRecordType_TIME_ONLY
