import { defineStyleConfig } from '@chakra-ui/react'

export const ImageUpload = defineStyleConfig({
  baseStyle: {
    uploadsContainer: {
      overflowX: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    imageContainer: {
      mr: '0.5rem',
      minW: '7rem',
      minH: '7rem',
      position: 'relative',
      borderRadius: '0.5rem',
    },
    imageUpload: {
      w: '7rem',
      h: '7rem',
      borderRadius: '0.5rem',
    },
    adminImageDisplay: {
      h: '28.125rem',
      borderRadius: 'inherit',
    },
    deleteIcon: {
      position: 'absolute',
      top: '4px',
      right: '4px',
      bg: 'black',
      opacity: '0.5',
      border: 'none',
      borderRadius: '50%',
      maxW: '1.75rem',
      maxH: '1.75rem',
      minW: '1.75rem',
      minH: '1.75rem',
      WebkitTapHighlightColor: 'transparent',
      _focus: {
        bg: 'black',
      },
      _active: {
        opacity: '0.8',
        bg: 'black',
      },
    },
    thumbnailContainer: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      gap: '0.5rem',
    },
    imageThumbnail: {
      w: '6rem',
      h: '6rem',
      borderRadius: '0.5rem',
    },
    button: {
      px: '0',
      w: '100%',
      h: '3.5rem',
      mb: '1rem',
      borderRadius: '0.5rem',
      textStyle: 'subhead-2',
    },
    addPhotoButton: {
      justifyContent: 'center',
      bgColor: 'base.canvas.brand-subtle',
      w: '100%',
      p: '0.625rem 1rem',
      border: '1px',
      borderColor: 'brand.primary.100',
    },
  },
  // TODO: combine with styling for add new button in record drawer
  variants: {
    isSecondaryButton: {
      addPhotoButton: {
        bgColor: 'base.canvas.default',
        p: '1rem',
        justifyContent: 'flex-start',
        color: 'interaction.main.default',
        border: '1px',
        borderColor: 'base.divider.medium',
      },
    },
  },
})
