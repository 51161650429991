import { PropsWithChildren, useEffect } from 'react'
import { WindowExpiringContextProvider } from './WindowExpiringContext'
import { useDatadogWithViewName } from '~/hooks/useDatadogWithViewName'
import { realUserMonitoring } from '~/monitoring/datadog'

/**
 * Helper to ensure all context providers required are included
 * Further initializes dd view tracing & rum inside the error boundary
 * @param param0 remaining app to render
 * @returns app wrapped with all context providers
 */
export const GlobalContextProvider = ({ children }: PropsWithChildren) => {
  useDatadogWithViewName()
  useEffect(() => {
    history.scrollRestoration = 'manual'
    realUserMonitoring()
  }, [])
  return (
    <WindowExpiringContextProvider>{children}</WindowExpiringContextProvider>
  )
}
