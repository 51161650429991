import { SectionVerificationCount } from '~/components/Header'
import { Lookup } from '~/server/modules/list/types'
import { MemoizeBySections } from '~/utils/decorators/MemoizeBySection'
import { ListTemplateView } from './ListTemplateView'
import {
  isRecordActive,
  isRecordInvalid,
} from '~/state/schemas/questions/utils'
import { RecordCardContent } from './record/CardContentView'
import { Item } from '@prisma/client'
import _ from 'lodash'

export class ListLookupView {
  private readonly rootListTemplateInstance: ListTemplateView
  private readonly submissionsInstance: SubmissionView[]
  private readonly rootListInstance: ListView
  constructor(listLookupData: Lookup) {
    this.rootListTemplateInstance = new ListTemplateView(
      listLookupData.listTemplate,
    )
    this.submissionsInstance = sortBySubmissionTimeDesc(
      listLookupData.submissions.map(
        (submission) => new SubmissionView(submission),
      ),
    )
    const {
      listTemplate: _listTemplate,
      submissions: _submissions,
      ...rest
    } = listLookupData
    this.rootListInstance = new ListView(rest)
  }
  get ongoingSubmission() {
    return (
      this.submissions.find((submission) => !submission.checkSubmitted) ?? null
    )
  }
  get previousSubmission() {
    return this.ongoingSubmission
      ? this.submissions.at(1) ?? null
      : this.latestSubmission ?? null
  }
  get latestSubmission() {
    return this.submissions.at(0) ?? null
  }
  get rootListTemplate() {
    return this.rootListTemplateInstance
  }
  get submissions() {
    return this.submissionsInstance
  }
  get rootList() {
    return this.rootListInstance
  }
  // slice to remove the root list template id
  @MemoizeBySections()
  public getSectionViewsById(sections: string[]) {
    if (!sections.length)
      throw new Error('provide a valid list of sections to traverse')
    const navigated = [this.rootListTemplate]
    let currentListTemplate = this.rootListTemplate
    sections.slice(1, sections.length).forEach((listTemplateId) => {
      const foundSection = currentListTemplate.sections.find(
        (l) => listTemplateId === l.id,
      )
      if (!foundSection)
        throw new Error(
          `could not locate section for listTemplateId ${listTemplateId}`,
        )
      currentListTemplate = new ListTemplateView(foundSection)
      navigated.push(currentListTemplate)
    })
    return navigated
  }

  @MemoizeBySections()
  public getCurrentSectionListTemplate = (sections: string[]) => {
    if (!sections.length)
      throw new Error('provide a valid list of sections to traverse')
    return this.getSectionViewsById(sections).at(-1) as ListTemplateView
  }

  @MemoizeBySections()
  public getRootListTemplate = (sections: string[]) => {
    if (!sections.length)
      throw new Error('provide a valid list of sections to traverse')
    return this.getSectionViewsById(sections).at(0) as ListTemplateView
  }

  @MemoizeBySections()
  public isCurrentSectionListRoot = (sections: string[]) => {
    return (
      this.getCurrentSectionListTemplate(sections).id ===
      this.rootListTemplate.id
    )
  }

  @MemoizeBySections()
  public getLastKnownSubmission = (): {
    submitter: string | null
    submitted: Date | null
    recordsIdMap: {
      [key: string]: RecordCardContent['record'] & { item: Item }
    }
  } | null => {
    const submission = this.submissions.find(
      (submission) => submission.checkSubmitted,
    )
    return submission
      ? {
          submitted: submission.submittedAt,
          submitter: submission.submitter?.name ?? null,
          recordsIdMap: submission.recordsIdMap,
        }
      : null
  }
}

export class SubmissionView {
  public readonly submission: Lookup['submissions'][number]
  public readonly recordsIdMap: {
    [recordId: string]: RecordCardContent['record'] & {
      item: Item
    }
  }
  public readonly recordsByItemId: {
    [itemId: string]: RecordCardContent['record'] & {
      item: Item
    }
  }
  constructor(submission: Lookup['submissions'][number]) {
    this.submission = submission
    this.recordsIdMap = _.chain(submission.records)
      .keyBy((record) => record.id)
      .value()
    this.recordsByItemId = _.chain(submission.records)
      .keyBy((record) => record.itemId)
      .value()
  }
  get latestInterimSubmission() {
    return this.submission.interimSubmissionTimestamps.at(-1) ?? null
  }
  get checkSubmitted() {
    return this.submission.submitted
  }
  get submitter() {
    return this.submission.submitter
  }
  get submissionCreatedAt() {
    return this.submission.createdAt
  }
  get submittedAt() {
    return this.submission.submitted
  }

  get id() {
    return this.submission.id
  }

  public containsIncompleteRecordsOrSections = (
    listTemplateView?: ListTemplateView,
  ): boolean => {
    const nestedSections = listTemplateView
      ?.getNestedSections()
      .map((section) => new ListTemplateView(section))

    const hasPendingSections = nestedSections?.some((section) => {
      return this.containsIncompleteRecordsOrSections(section)
    })
    const hasPendingRecords = Object.values(this.recordsIdMap).some(
      (record) =>
        isRecordActive(record, this.recordsByItemId) && isRecordInvalid(record), // the record is active, but invalid
    )
    return hasPendingSections || hasPendingRecords
  }

  public getItemVerificationCountsForSection = (
    recordByItemId: Record<
      string,
      RecordCardContent['record'] & { item: Item }
    >,
    sectionListView: ListTemplateView,
  ): SectionVerificationCount => {
    const allItemsInSection = sectionListView.getNestedItems()
    const recordsInSection = _.compact(
      allItemsInSection.map((nestedItem) => {
        const record = recordByItemId[nestedItem.id]
        if (!record)
          throw new Error(
            `Record for item ${nestedItem.name} could not be found`,
          )
        return isRecordActive(record, this.recordsByItemId) ? record : undefined
      }),
    )
    return {
      verified: recordsInSection.filter((record) => !isRecordInvalid(record))
        .length,
      total: recordsInSection.length,
    }
  }
}

export class ListView {
  private readonly list: Omit<Lookup, 'listTemplate' | 'submissions'>
  constructor(list: Omit<Lookup, 'listTemplate' | 'submissions'>) {
    this.list = list
  }
  get id() {
    return this.list.id
  }
  get name() {
    return this.list.name
  }
}

export const sortBySubmissionTimeDesc = (submissions: SubmissionView[]) => {
  return submissions.sort(
    (a, b) => b.submissionCreatedAt.getTime() - a.submissionCreatedAt.getTime(),
  )
}
