import { PropsWithChildren, useEffect } from 'react'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'
import { browserEnv } from './browserEnv'
import { useRouter } from 'next/router'

export enum ACTIVE_FEATURE_FLAGS {
  ENABLE_SKIP_CHECK = 'enable-skip-check',
  ENABLE_ADD_SCORING = 'enable-add-scoring',
}

export type FeatureFlagTypes = `${ACTIVE_FEATURE_FLAGS}`

const enableDevMode = browserEnv.NEXT_PUBLIC_DD_ENV === 'development'

/**
This works on both the client and server, refer to https://docs.growthbook.io/lib/js
In browser environments, you typically want a single global GrowthBook instance.
In server environments, you instead want a separate GrowthBook instance for every incoming request
@returns a new growthbook instance
 */
export const newGrowthbook = () => {
  return new GrowthBook({
    apiHost: browserEnv.NEXT_PUBLIC_GROWTHBOOK_API_HOST,
    clientKey: browserEnv.NEXT_PUBLIC_GROWTHBOOK_CLIENT_KEY,
    enableDevMode,
    subscribeToChanges: true,
  })
}

export const growthbook = newGrowthbook()

export const GrowthBookWrapper = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const router = useRouter()
  useEffect(() => {
    // Load features asynchronously when the app renders
    growthbook.init({ streaming: true })
    growthbook.setAttributes({
      listId: router.query.listId,
    })
  }, [router])

  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  )
}
